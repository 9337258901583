<template>
  <div class="realistic">
    <div class="realistic-wrapper">
      <div class="zlxc-container" style="overflow: visible">
        <div class="bread-crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>题库练习</el-breadcrumb-item>
            <el-breadcrumb-item>全真模拟</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="div" v-if="mockExamDataList && mockExamDataList.length > 0">
          <div class="simulation">
            <!-- 左侧考试信息栏目 -->
            <div class="exam-information">
              <div class="info-suject">
                {{ stepType == 1 ? "科目一" : "科目四" }}
                <p class="suject-title">理论考试</p>
              </div>
              <div class="info-test">
                <div class="test-avator">
                  <img
                    class="avator-img"
                    src="../../../assets/zlxctheory/second_img_moren@2x.png"
                  />
                </div>
                <p class="test-orther">
                  考试题数：{{ mockExamDataList.length }}题
                </p>
                <p class="test-orther">考试时间：45分</p>
                <p class="test-orther">满分100分</p>
                <p class="test-orther">90分及格</p>
              </div>
              <div class="info-suject">
                {{msg}}
                <p class="suject-title">剩余时间</p>
              </div>
            </div>
            <!-- 中间考试题目 -->
            <div class="exam-topic" v-show="currentMockExamData">
              <div class="topic">
                <div class="topic-sub">理论考试</div>
                <div class="topic-title">
                  <p class="topic-num">{{ currentTopic + 1 }}、</p>
                  <p class="topic-name">
                    {{ currentMockExamData.title }}
                  </p>
                </div>
                <div class="topic-options">
                  <el-radio-group
                    v-if="currentMockExamData.types == 1"
                    v-model="currentMockExamData.userAnswer"
                    @change="radioExamChange"
                  >
                    <el-radio label="A" v-if="currentMockExamData.optiona"
                      >A、{{ currentMockExamData.optiona }}</el-radio
                    >
                    <el-radio label="B" v-if="currentMockExamData.optionb"
                      >B、{{ currentMockExamData.optionb }}</el-radio
                    >
                    <el-radio label="C" v-if="currentMockExamData.optionc"
                      >C、{{ currentMockExamData.optionc }}</el-radio
                    >
                    <el-radio label="D" v-if="currentMockExamData.optiond"
                      >D、{{ currentMockExamData.optiond }}</el-radio
                    >
                  </el-radio-group>
                  <el-checkbox-group
                    v-if="currentMockExamData.types == 2"
                    v-model="currentMockExamData.userAnswerList"
                    @change="checkExamChange"
                  >
                    <el-checkbox label="A" v-if="currentMockExamData.optiona"
                      >A、{{ currentMockExamData.optiona }}</el-checkbox
                    >
                    <el-checkbox label="B" v-if="currentMockExamData.optionb"
                      >B、{{ currentMockExamData.optionb }}</el-checkbox
                    >
                    <el-checkbox label="C" v-if="currentMockExamData.optionc"
                      >C、{{ currentMockExamData.optionc }}</el-checkbox
                    >
                    <el-checkbox label="D" v-if="currentMockExamData.optiond"
                      >D、{{ currentMockExamData.optiond }}</el-checkbox
                    >
                  </el-checkbox-group>
                  <el-radio-group
                    v-if="currentMockExamData.types == 3"
                    v-model="currentMockExamData.userAnswer"
                    @change="radioExamChange"
                  >
                    <el-radio label="true" v-if="currentMockExamData.optiona">{{
                      currentMockExamData.optiona
                    }}</el-radio>
                    <el-radio
                      label="false"
                      v-if="currentMockExamData.optionb"
                      >{{ currentMockExamData.optionb }}</el-radio
                    >
                  </el-radio-group>
                </div>
                <div
                  class="topic-note"
                  v-show="
                    currentMockExamData.userAnswer &&
                    currentMockExamData.userAnswer != ''
                  "
                >
                  <span class="outcome"
                    >回答{{
                      currentMockExamData.userAnswer ==
                      currentMockExamData.answer
                        ? "正确"
                        : "错误"
                    }}！</span
                  >正确答案：<span class="answer">{{
                    currentMockExamData.answer == "true"
                      ? "正确"
                      : currentMockExamData.answer == "false"
                      ? "错误"
                      : currentMockExamData.answer
                  }}</span>
                </div>
              </div>
              <div class="topic-prompt">
                <div class="prompt">
                  {{
                    currentMockExamData.types == 1
                      ? "单选题"
                      : currentMockExamData.types == 2
                      ? "多选题"
                      : "判断题"
                  }}，请选择您认为正确的答案
                  <p class="suject-title">试题提示信息</p>
                </div>
                <div class="manipulate">
                  <p
                    :class="['btn', { disabled: currentTopic == 0 }]"
                    @click="tapPreviousQuestion"
                  >
                    上一题
                  </p>
                  <p
                    :class="[
                      'btn',
                      { disabled: currentTopic == mockExamDataList.length - 1 },
                    ]"
                    @click="tapNextQuestion"
                  >
                    下一题
                  </p>
                  <p class="btn submit" @click="submitExam">交卷</p>
                </div>
              </div>
            </div>
            <!-- 右侧答题卡 -->
            <div class="exam-card">
              <ul class="card-list">
                <!-- <li class="card mistake">1</li>
                <li class="card proper">2</li>
                <li class="card correct">3</li> -->
                <li
                  :class="[
                    'card',
                    { correct: currentTopic == index },
                    {
                      proper: item.userAnswer && item.answer == item.userAnswer,
                    },
                    {
                      mistake:
                        item.userAnswer && item.answer != item.userAnswer,
                    },
                  ]"
                  v-for="(item, index) in mockExamDataList"
                  :key="item.id"
                  @click="tapCurrentTopicBtn(index)"
                >
                  {{ index + 1 }}
                </li>
              </ul>
            </div>
          </div>
          <div class="photograph">
            <div class="photo-title">图片信息</div>
            <div
              class="photo"
              v-if="
                currentMockExamData.icon.substring(
                  currentMockExamData.icon.length - 4
                ) != 'null'
              "
            >
              <img
                v-show="
                  currentMockExamData.icon.substring(
                    currentMockExamData.icon.length - 3
                  ) != 'mp4'
                "
                class="photo-img"
                :src="currentMockExamData.icon"
              />
              <video
                v-show="
                  currentMockExamData.icon.substring(
                    currentMockExamData.icon.length - 3
                  ) == 'mp4'
                "
                class="photo-img"
                :src="currentMockExamData.icon"
                autoplay
                controls
                controlslist="nodownload nofullscreen noremoteplayback"
              ></video>
            </div>
          </div>
        </div>
        <el-empty v-else :image-size="200"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { queryGetMockExam } from "@/api/herbalMedicine";

export default {
  name: "RealisticSimulation",
  data() {
    return {
      // 车型
      carType: "car",
      // 科目
      stepType: 1,
      // 模拟考试题目
      mockExamDataList: null,
      // 当前题目
      currentTopic: 0,
      // 当前题目列表
      currentMockExamData: null,
      // 考试时间
      maxtime: 45 * 60, // 45分钟，按秒计算
      // 当前计时
      msg: "45:00",
    };
  },
  computed: {},
  created() {
    this.carType =
      this.$route.query.carType == "trolley"
        ? "car"
        : this.$route.query.carType;
    this.stepType = this.$route.query.stepType == "branchOne" ? 1 : 4;
    this.getQueryGetMockExam();
  },
  mounted() {
    this.timeCountDown()
  },
  methods: {
    // 倒计时方法
    timeCountDown() {
      let timer = setInterval(() => {
        if (this.maxtime > 0) {
          let minutes = Math.floor(this.maxtime / 60);
          let seconds = Math.floor(this.maxtime % 60);
          // "距离结束还有" + minutes + "分" + seconds + "秒"
          if (seconds < 10) seconds += "0"
          this.msg = minutes + ":" + seconds;
          --this.maxtime;
        } else {
          clearInterval(timer);
          this.submitExam()
        }
      }, 1000);
    },
    // 当前选择题目的绑定值变更类型
    changeCurUserAnswer(obj) {
      let _obj = JSON.parse(JSON.stringify(obj));
      if (_obj) {
        for (let key in _obj) {
          // console.log(key + '---' + obj[key])
          if (_obj["types"] == 2) {
            _obj["userAnswerList"] = _obj["userAnswer"]
              ? _obj["userAnswer"].split("")
              : [];
          }
        }
        this.currentMockExamData = _obj;
      }
    },
    // 获取模拟考试题
    getQueryGetMockExam() {
      queryGetMockExam({
        carType: this.carType,
        stepType: this.stepType,
      }).then((res) => {
        if (res.data.length > 0) {
          // res.data.forEach((item) => {
          //   item["chooseAnswer"] = "";
          // })
          this.mockExamDataList = res.data;
          this.changeCurUserAnswer(res.data[this.currentTopic]);
        }
      });
    },
    // 单选题改变触发
    radioExamChange(value) {
      this.mockExamDataList[this.currentTopic].userAnswer = value;
    },
    // 多选题改变触发
    checkExamChange(value) {
      value.sort(function (a, b) {
        return a.localeCompare(b);
      });
      let _result = value.join("");
      this.currentMockExamData["userAnswer"] = _result;
      this.mockExamDataList[this.currentTopic].userAnswer = _result;
    },
    // 触发上一题
    tapPreviousQuestion() {
      if (this.currentTopic == 0) return;
      this.currentTopic--;
      this.changeCurUserAnswer(this.mockExamDataList[this.currentTopic]);
    },
    // 触发下一题
    tapNextQuestion() {
      if (this.currentTopic == this.mockExamDataList.length - 1) return;
      this.currentTopic++;
      this.changeCurUserAnswer(this.mockExamDataList[this.currentTopic]);
    },
    // 点击答题卡触发
    tapCurrentTopicBtn(index) {
      this.currentTopic = index;
      this.changeCurUserAnswer(this.mockExamDataList[this.currentTopic]);
    },
    submitExam() {
      let proper = 0,mistake = 0,total = 0, score = 0
      let _mockExamDataList = this.mockExamDataList
      _mockExamDataList.map(item => {
        if (item.userAnswer && item.userAnswer == item.answer) {
          proper ++
        } else if (item.userAnswer && item.userAnswer != item.answer) {
          mistake ++
        }
      })
      total = _mockExamDataList.length
      if (total == 50) {
        score = proper * 2
      } else {
        score = proper
      }
      if (score >= 90) {
        this.$router.push({
          name:'ExamPassed',
          query: {
            proper,
            mistake,
            total,
            score,
            maxtime: this.maxtime
          }
        })
      } else {
        this.$router.push({
          name:'ExamDnotpass',
          query: {
            proper,
            mistake,
            total,
            score,
            maxtime: this.maxtime
          }
        })
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
